import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import { useAppContext } from '../../context';
import { IProduct } from '../../types';
import { useFilters } from './useFilters';
import {
  FiltersStyled,
  Header,
  PopularFiltersStyled,
} from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import 'swiper/css';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { useConfiguration } from '../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../queries';
import { PillFiltersComponent } from './PillFilters';
import { PillFiltersWithImageComponent } from './PillFiltersWithImage';

export const PopularFilters = ({
  products,
  categories,
  brands,
  productsBeforeFiltering
}: {
  products: IProduct[];
  productsBeforeFiltering: IProduct[];
  categories: any;
  brands: string[];
}) => {
  const {
    value: { productsSorting, searchFilters },
    dispatch,
  } = useAppContext();

  const { getCustomizedFilterData, handleFiltersVisibility } = useFilters(
    {
      products,
      productsBeforeFiltering,
    }
  );

  const navigate = useNavigate();

  const { storeId = '', termId, harvestId = '' } = useParams();

  const { LanguageVersion, FilterType, PillFiltersWithImageDisplayStyle } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  const { data, loading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split('__')[0],
    })
  );

  const edges = data?.getEPogTermListing?.edges;

  let filters = edges
      .map((edge: any) => edge?.node?.PillFilters)
      .flat()
  let filtersWithImage = edges
      .map((edge: any) => edge?.node?.PillFiltersWithImage)
      .flat();

  return (
    <FiltersStyled>
      {(products?.length || (!products?.length && searchFilters?.length)) && FilterType ? <Header>{translation?.getLocalization.PopularFilters}</Header> : ''}
      <PopularFiltersStyled isLessThanSixItems={categories?.length}>
        {categories?.map((category: any, index: number) => (
          (category?.TermImage?.fullpath) ? 
          <div
            className="filter"
            key={index}
            onClick={() => 
              navigate(
                `/${storeId}/${harvestId}/category/${
                  category.id
                }__${category.key.replaceAll(' ', '_')}`
              )
            }
          >
            <img
              src={
                category?.TermImage?.fullpath
                  ? `${imgSrcByEnv()}${category?.TermImage?.fullpath}`
                  : '/files/generic/img/square.svg'
              }
              alt="carousel"
            />
            {/* <img src="/files/generic/img/square.svg" alt="" /> */}
            <p> {category?.key}</p>
          </div> : ''
        ))}
      </PopularFiltersStyled>

      {(products.length || (!products?.length && searchFilters?.length)) &&
        (FilterType == "Plain" || FilterType == "All") &&
        <PillFiltersComponent filters={filters} getCustomizedFilterData={getCustomizedFilterData} handleFiltersVisibility={handleFiltersVisibility} />
      }
      {(products.length || (!products?.length && searchFilters?.length)) &&
        (FilterType == "Image" || FilterType == "All") &&
        (PillFiltersWithImageDisplayStyle === "WithoutImages" ?
          <PillFiltersComponent filters={filtersWithImage} getCustomizedFilterData={getCustomizedFilterData} squareFilterStyling={true} handleFiltersVisibility={handleFiltersVisibility} /> :
          <PillFiltersWithImageComponent filters={filtersWithImage} getCustomizedFilterData={getCustomizedFilterData} />
        )
      }
    </FiltersStyled>
  );
      
};
