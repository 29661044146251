import React from 'react';

import { useParams } from 'react-router-dom';
import { useAppContext } from '../../context';
import { actions } from '../../context/actions';
import { useConfiguration } from '../../hooks/useConfiguration';
import { IProduct } from '../../types';
import { ModalTypes } from '../Modals/types';
import { contentSquarePageEvent } from '../../helpers';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';

export const useFilters = ({
  products,
  productsBeforeFiltering,
}: {
  products: IProduct[];
  productsBeforeFiltering: IProduct[];
}) => {
  const [isPriceFilter, setIsPriceFilter] = React.useState(false);
  const [isBrandsFilter, setIsBrandsFilter] = React.useState(false);
  const [isCategoriesFilter, setIsCategoriesFilter] = React.useState(false);
  const [isSpecialOffersFilter, setIsSpecialOffersFilter] = React.useState(false);
  const [isCustomFilter, setIsCustomFilter] = React.useState<string[]>([]);
  const { storeId = '', harvestId = '' } = useParams();
  const { FilterVisibility, LanguageVersion } = useConfiguration({ storeId, harvestId });

  const {
    value: { searchFilters, isInlineFilters, isModal },
    dispatch,
  } = useAppContext();

  const { data: translation } = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );
  
  const priceFilter = searchFilters.find(
    (filter: any) => filter.name === 'Price'
  );
  const ratingFilter = searchFilters.find(
    (filter: any) => filter.name === 'Rating'
  );
  const variantsFilter = searchFilters.find(
    (filter: any) => filter.name === 'Variants'
  );

  const handleFiltersVisibility = () => {
    if (window.innerWidth < 768) {
      dispatch({
        type: actions.TOGGLE_MODAL,
        payload: {
          isModal: !isModal,
          modalType: ModalTypes.filtersMenu,
          modalContent: { productsBeforeFiltering },
        },
      });
    } else {
      if (FilterVisibility === 'Stick') {
        dispatch({
          type: actions.TOGGLE_INLINE_FILTERS,
          payload: {
            isInlineFilters: !isInlineFilters,
          },
        });
      } else {
        dispatch({
          type: actions.TOGGLE_MODAL,
          payload: {
            isModal: !isModal,
            modalType: ModalTypes.filtersMenu,
            modalContent: { productsBeforeFiltering },
          },
        });
      }
    }
  };

  const getCustomizedFilterData = (filterElement: any) => {
    let filterData = { key: filterElement?.Name, title: filterElement?.Name, clickHandler: () => { }, colors: '' }
    switch (filterElement?.Name) {
      case 'Brand':
        filterData.title = translation?.getLocalization.Brand;
        filterData.colors = isBrandsFilter ? 'dark' : 'light';
        filterData.clickHandler = () => {
          contentSquarePageEvent('brandSelectFilter')
          dispatch({
            type: actions.TOGGLE_MODAL,
            payload: {
              isModal: true,
              modalType: ModalTypes.brands,
              modalContent: { productsBeforeFiltering },
            },
          });
        }
        break;
      case 'Price':
        filterData.title = translation?.getLocalization.Price;
        filterData.colors = isPriceFilter ? 'dark' : 'light';
        filterData.clickHandler = () => {
          contentSquarePageEvent('priceFilter')
          dispatch({
            type: actions.TOGGLE_MODAL,
            payload: {
              isModal: true,
              modalType: ModalTypes.priceRange,
              modalContent: { productsBeforeFiltering },
            },
          });
        };
        break;
      case 'Categories':
        filterData.title = translation?.getLocalization.Categories;
        filterData.colors = isCategoriesFilter ? 'dark' : 'light';
        filterData.clickHandler = () => {
          contentSquarePageEvent('categoriesFilter')
          dispatch({
            type: actions.TOGGLE_MODAL,
            payload: {
              isModal: true,
              modalType: ModalTypes.categories,
              modalContent: { productsBeforeFiltering },
            },
          });
        }
        break;
      case 'Rating':
        filterData.title = translation?.getLocalization.Rating;
        filterData.colors = ratingFilter?.value ? 'dark' : 'light';
        filterData.clickHandler = () => {
          contentSquarePageEvent('ratingFilter')
          dispatch({
            type: actions.TOGGLE_MODAL,
            payload: {
              isModal: true,
              modalType: ModalTypes.rating,
            },
          });
        };
        break;
      case 'Special Offers':
        filterData.title = translation?.getLocalization.SpecialOffers;
        filterData.colors = isSpecialOffersFilter ? 'dark' : 'light';
        filterData.clickHandler = () => {
          contentSquarePageEvent('specialOffersFilter')
          dispatch({
            type: actions.TOGGLE_MODAL,
            payload: {
              isModal: true,
              modalType: ModalTypes.specialOffers,
              modalContent: { productsBeforeFiltering },
            },
          });
        };
        break;
      case 'Variants':
        filterData.title = translation?.getLocalization.Variants;
        filterData.colors = variantsFilter?.value ? 'dark' : 'light';
        filterData.clickHandler = () => {
          contentSquarePageEvent('variantFilter')
          dispatch({
            type: actions.TOGGLE_MODAL,
            payload: {
              isModal: true,
              modalType: ModalTypes.variants,
            },
          });
        };
        break;
      default:
        filterData.key = 'Custom_' + filterElement.Name;
        filterData.colors = (isCustomFilter.includes('Custom_' + filterElement.Name) ? 'dark' : 'light');
        filterData.clickHandler = () => {
          dispatch({
            type: actions.ADD_SEARCH_FILTER,
            payload: {
              name: 'Custom_' + filterElement.Name,
              value: filterElement.Name,
              checked: isCustomFilter.includes('Custom_' + filterElement.Name) ? false : true,
            },
          });
        }

        break;
    }
    return filterData;
  };

  React.useEffect(() => {
    if (
      priceFilter?.userSelectedPriceFilterMin ||
      priceFilter?.userSelectedPriceFilterMax
    ) {
      setIsPriceFilter(true);
    } else {
      setIsPriceFilter(false);
    }
  }, [searchFilters, priceFilter]);

  React.useEffect(() => {
    const brandsFilter = searchFilters.find(
      (filter: any) => filter.name === 'Brand'
    );
    const brands =
      brandsFilter && brandsFilter.brands && Object.values(brandsFilter.brands);
    const isBrands = brands && brands.some((brand: any) => brand === true);
    setIsBrandsFilter(isBrands);
  }, [searchFilters]);

  React.useEffect(() => {
    const categoriesFilter = searchFilters.find(
      (filter: any) => filter.name === 'Categories'
    );
    const categories =
    categoriesFilter && categoriesFilter.categories && Object.values(categoriesFilter.categories);
    const isCategory = categories && categories.some((categories: any) => categories === true);
    setIsCategoriesFilter(isCategory);
  }, [searchFilters]);

  React.useEffect(() => {
    const specialOffersFilter = searchFilters.find(
      (filter: any) => filter.name === 'Special Offers'
    );
    const promo =
    specialOffersFilter && specialOffersFilter.promo && Object.values(specialOffersFilter.promo);
    const isPromo = promo && promo.some((promo: any) => promo === true);
    setIsSpecialOffersFilter(isPromo);
  }, [searchFilters]);

  React.useEffect(() => {
    if (searchFilters?.length < 1) setIsCustomFilter([]);
    const customFilter = searchFilters.findLast(
      (filter: any) => filter.name.includes("Custom_") // === 'Custom_Pampers'
    );
    const CustomFilter =
    customFilter && customFilter.name; //&& Object.values(customFilter.value);
    if (CustomFilter != undefined && !isCustomFilter.includes(CustomFilter)) {
      setIsCustomFilter(isCustomFilter => [...isCustomFilter!, CustomFilter]);
    } else if (isCustomFilter.includes(CustomFilter)) {
      let removeCustomFilter = isCustomFilter.filter((Filter: string) => Filter != CustomFilter)
      setIsCustomFilter(removeCustomFilter);
    }
  }, [searchFilters]);

  //on filters component first load initialize price range minimum and maximum within searchFilters store
  return {
    isPriceFilter,
    isBrandsFilter,
    isCategoriesFilter,
    isSpecialOffersFilter,
    isCustomFilter,
    translation,
    handleFiltersVisibility,
    getCustomizedFilterData
  };
};
